.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(55,55,55,.4);
  z-index:9999 ;
}

.spinner-position {
  position: absolute;
  top:49%;
  right:49%
}
