// Here you can add other styles

.search-highlight {
  background: yellow;
}

.cursor-pointer {
  cursor: pointer;
}

.text-bold {
  font-weight: bold;
}

.quill-editing-area {
  .ql-editor {
    font-size: 16px;
    p + p {
      margin-top: 10px;
    }
  }
}
